import React from 'react'
import { Box } from '@chakra-ui/react'
import BackgroundImage from 'gatsby-background-image'
import { H3 } from '../../theme/Headings'

const TextImage = ({ overlayOpacity, overlayColor, heading, body, img, color, className }) => {
  return (
    <BackgroundImage
      className={`csBlock blockWithBG blockShadow${className ? ` ${className}` : ``}`}
      fluid={img}
      style={{position: 'relative', color: color}}
    >
      <Box
        position="absolute"
        width="100%" height="100%" top="0" left="0"
        background={overlayColor}
        opacity={overlayOpacity}
        zIndex="0"
      />
      <H3 position="relative" zIndex="1">{heading}</H3>
      <Box as="p" mb="0" position="relative" zIndex="1" dangerouslySetInnerHTML={{ __html: body }} />
    </BackgroundImage>
  )
}


export default TextImage