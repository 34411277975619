import React from 'react'
import Img from 'gatsby-image'

const PlainImage = ({ img, alt, size = 'normal', className }) => {
  return (
    <Img
      className={`csBlock blockShadow${size !== 'normal' ? '' : ' blockWithBG'}${className ? ` ${className}` : ``}`}
      fluid={img}
      alt={alt}
      style={{minHeight: '480px'}}
    />
  )
}

export default PlainImage