import React from 'react'
import { SimpleGrid, Container } from '@chakra-ui/react'
import { breakpoints } from '../../theme/themeAlopex'

const Columns = ({ blockLeft, blockRight, className }) => {
  return (
    <Container className={`csBlock${className ? ` ${className}` : ``}`} maxWidth={breakpoints.lg} mb="25px" p="0">
      <SimpleGrid columns={[1, null, null, 2]} gap="25px">
        {blockLeft}
        {blockRight}
      </SimpleGrid>
    </Container>
  )
}

export default Columns