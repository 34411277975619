import React from 'react'
import { Box, Container } from '@chakra-ui/react'
import { spacing, breakpoints } from '../../theme/themeAlopex'
import { H3 } from '../../theme/Headings'

const PlainText = ({ heading, body, color, bgColor, size = 'normal', className }) => {
  return (
    <Container
      className={`csBlock blockShadow${className ? ` ${className}` : ``}`}
      maxWidth={breakpoints.lg}
      backgroundColor={bgColor}
      pt={spacing.groups}
      pb={spacing.groups}
      pl={() => { return size !== 'normal' ? '50px' : [spacing.icon, null, '150px'] }}
      pr={() => { return size !== 'normal' ? '50px' : [spacing.icon, null, '150px'] }}
      mb={() => { return size !== 'normal' ? 0 : '25px'}}
      color={color}
      minHeight="430px"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
    >
      <H3>{heading}</H3>
      <Box as="p" dangerouslySetInnerHTML={{ __html: body }} />
    </Container>
  )
}


export default PlainText