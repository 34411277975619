import React, { useEffect } from "react"
import { graphql } from "gatsby"
import {
  Container,
} from '@chakra-ui/react'
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import { H1 } from '../theme/Headings'
import { spacing, breakpoints, colors } from '../theme/themeAlopex'
import PlainText from '../components/CaseStudies/PlainText'
import PlainImage from '../components/CaseStudies/PlainImage'
import ImageText from '../components/CaseStudies/ImageText'
import Columns from '../components/CaseStudies/Columns'
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const AKNAVPage = ({ data }) => {
  // gsap's scrollTrigger for fade-up
  const gsapFadeUp = (csBlocks) => {
    for (let i = 0; i < csBlocks.length; ++i) {
      gsap.from(csBlocks[i], {
        scrollTrigger: {
          trigger: csBlocks[i],
          start: 'top 75%',
          // marker: true
        },
        duration: 1,
        y: 50,
        opacity: 0,
      })
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const csBlocks = document.querySelectorAll('.csBlock')
      gsapFadeUp(csBlocks)
    }
  }, [])

  return (
    <Layout>
      <SEO title={`AlaskaNavigator.org Progressive Web App - ${data.site.siteMetadata.title}`} noIndex />
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 color={colors.brand.slate} textAlign="center" mt={spacing.header} mb={spacing.groups}>AlaskaNavigator.org Progressive Web App</H1>
      </Container>
      {/** BLOCK 01 */}
      <ImageText
        heading="Brief"
        body="As Spawn Ideas’ digital partner, Alopex was tasked with overhauling DOT’s public facing project search system
        AlaskaNavigator.org. The original site was not responsive, alienating a majority of traffic to the site and suffered
        from multiple usability issues on both the front facing and engineer side of the website. After an audit of the user
        interfaces of the website, Alopex recommended a change of hosts, a migration to Drupal 8 (from Drupal 7), and a
        complete redesign."
        img={data.construction.childImageSharp.fluid}
        color={colors.brand.slate}
        overlayColor="#ffdd00"
        overlayOpacity="0.9"
      />
      {/** BLOCK 02 */}
      <PlainImage img={data.responsive.childImageSharp.fluid} />
      {/** BLOCK 03 */}
      <PlainText
        color={colors.brand.lightGray}
        bgColor="#505c66"
        heading="Design"
        body="Working with our agency partner, Alopex wireframed and prototyped a new user interface that would be completely
        responsive. While the public facing site is quite simple, using a search to find specific projects and read about them,
        there is incredible complexity in the way that that data needed to be input. The site uses minimal colors and styles,
        the template system compresses logically from HD desktop, desktop, tablet, to mobile."
      />
      {/** BLOCK 04 */}
      <PlainImage img={data.map.childImageSharp.fluid} />
      {/** BLOCK 05 */}
      <PlainText
        color={colors.brand.slate}
        bgColor="#ffdd00"
        heading="Development"
        body="AlaskaNavigator.org is constructed on Drupal 8. Project engineers, managers, marketers, and administrators have access
        to different tools on the backend of the site, allowing projects to be precisely mapped and managed. A major aspect of the
        overhaul was the integration of Google Maps for project mapping—project managers can easily add routes, draw boundaries, and
        search for projects in a refined interface that scales beautifully for visitors to the website. Categorization of projects is
        automatic and makes finding a project a smooth process—using either the project search or the interactive map with custom
        designed markers."
      />
      {/** Block 06 */}
      <Columns
        blockLeft={
          <PlainImage img={data.repsonsive2.childImageSharp.fluid} size="small" />
        }
        blockRight={
          <PlainImage img={data.map2.childImageSharp.fluid} size="small" />
        }
      />
      {/** BLOCK 07 */}
      <PlainText
        color={colors.brand.lightGray}
        bgColor="#505c66"
        heading="Results"
        className="lastBlock"
        body="The new AlaskaNavigator.org saves all parties managing it a ton of time and money. With a refined content input system
        on the backend, procedures take far less time and are more responsive to the user’s environment and needs. The redesign has
        allowed users to access information on-the-go and the results are beginning to show in Google Analytics. As of writing this,
        mobile page views are up 150% over the previous year, tablet is up by over 20%, and desktop by almost 70%. The migration to
        Drupal 8 and managed hosting through Pantheon has made the website far faster, earning exemplary results in almost every test
        that can be performed on it."
      />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query aknavPage {
    site {
      siteMetadata {
        title
      }
    }
    construction: file(relativePath: { eq: "casestudies/aknav/iStock-1166800568.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    responsive: file(relativePath: { eq: "casestudies/aknav/ALOPEX_AKNAV-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    map: file(relativePath: { eq: "casestudies/aknav/ALOPEX_AKNAV-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    repsonsive2: file(relativePath: { eq: "casestudies/aknav/ALOPEX_AKNAV-1-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    map2: file(relativePath: { eq: "casestudies/aknav/ALOPEX_AKNAV-2-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default AKNAVPage
